import { Component, Inject, OnInit,ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { BulkSummaryComponent } from 'src/app/components/bulk-employee-import/bulk-summary/bulk-summary.component';
import { BulkEmployeeImportService } from 'src/app/services/bulkImport/bulk-employee-import.service';
import { BaseLocation } from 'src/app/services/user/user-enum';
import { OfficeAttendanceService } from 'src/app/services/office-attendance/office-attendance.service';
import { ToastrService } from 'ngx-toastr';
import { OfficeAttendanceAdminComponent } from '../office-attendance-admin/office-attendance-admin/office-attendance-admin.component';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-office-attendance-import-dialog',
  templateUrl: './office-attendance-import-dialog.component.html',
  styleUrls: ['./office-attendance-import-dialog.component.scss']
})
export class OfficeAttendanceImportDialogComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  public fileName = '';
   public xlFile: File;
   public response: any;
   public allfileContent: any;
   public badFileFormat: boolean;
   public displayStyle = 'none';
   public confirmImportFlag: boolean;
   public uploadBtnLabel = 'Select File';
   public errorMessage = "";
   public reuploadText = "Want to re-upload records?";
   public isReuploading:boolean = false;
   public city:string='Bangalore';
   baseLocations=[];
   selectedBaseLocation;
   public headingText: string;
   isSelected:boolean = false;
   public importText;
 
   constructor(
    public dialogRef: MatDialogRef<OfficeAttendanceAdminComponent>,
     private officeAttendanceService: OfficeAttendanceService,
     public dialog: MatDialog,
     private _location: Location,
     private toasterService: ToastrService,
     private userService: UserService,
     @Inject(MAT_DIALOG_DATA) public data: any
   ) {
    this.headingText = data.headingText; 
    this.isReuploading = data.isReuploading;
   }
 
   ngOnInit(): void {
    for (const baseLocation of Object.values(BaseLocation)) {
      if (isNaN(Number(baseLocation))) { 
        this.baseLocations.push(baseLocation);
      }
    }
    if(this.data.hideReupload==true){
      this.importText="Import Slack Ids"
    }else{
      this.importText="Import Attendance"
    }
    this.selectedBaseLocation = this.baseLocations.length ? this.baseLocations[0] : null;
  }
  
 
   //Read selected file's name, and assign xlFile the selected file, and set fileName to file name to display on screen
   readFile(event: Event) {
    this.isSelected=true;
     this.xlFile = event[0];
     if (this.xlFile) {
       this.fileName = this.xlFile.name;
     }
   }
 
   //On clicking upload button, create form data with the selected xl file, and call the service to trigger the API
   uploadFile() {
     this.confirmImportFlag = false;
     const formData = new FormData();
     formData.append('file', this.xlFile);
     formData.append('city',this.selectedBaseLocation);
     this.uploadBtnLabel = 'Importing Records...';
     this.fileName = 'Please wait...';
     if(!this.data.hideReupload){
     this.officeAttendanceService.uploadBulkAttendance(formData, this.isReuploading).subscribe(
       (data) => {
         this.toasterService.info("Attendance Records Uploaded")
         this.closeDialog();
         this.isSelected=false;
       },
       (error) => {
        this.toasterService.error(error.error.title)
         this.errorMessage = error.error.title;
         this.uploadBtnLabel = 'Select File';
         this.fileName = '';
         this.fileInput.nativeElement.value = '';
         this.isSelected=false;
       }
     );
    }
    else{
      this.userService.uploadSlackIds(formData).subscribe(
        (data) => {
          this.toasterService.info("Slack IDs Uploaded")
          this.closeDialog();
          this.isSelected=false;
        },
        (error) => {
         this.toasterService.error(error.error)
          this.errorMessage = error.error;
          this.uploadBtnLabel = 'Select File';
          this.fileName = '';
          this.isSelected=false;
          this.fileInput.nativeElement.value = '';
        }
      );
    }
 
     //Reset variables
     this.allfileContent = '';
     this.response = '';
     this.xlFile = null;
   }

   changeToReupload(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '40%';
    dialogConfig.height = '35%';
    dialogConfig.data = {hideReupload: false,isReuploading:true,headingText:"Re-upload Office Attendance"};

    const dialogRef = this.dialog.open(OfficeAttendanceImportDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.closeDialog();
    });
   }
 
   closeDialog() {
     this.dialogRef.close();
   }
 }