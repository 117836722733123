import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-export-employee-details',
  templateUrl: './export-employee-details.component.html',
  styleUrls: ['./export-employee-details.component.scss']
})
export class ExportEmployeeDetailsComponent implements OnInit {
  generateButtonText: string = 'Generate Report';
  generateDisabled: boolean = false;
  fields = [{ name: 'Name', checked: true, mandatory: true }, { name: 'Employee Id', checked: true, mandatory: true }, {
    name: 'Official Email',
    checked: true,
    mandatory: true
  }, { name: 'Title', checked: true, mandatory: true }, { name: 'Date of Joining', checked: true, mandatory: true }, {
    name: 'Capability',
    checked: false,
    mandatory: false
  }, { name: 'Career Manager', checked: false, mandatory: false }, {
    name: 'Mobile No.',
    checked: false,
    mandatory: false
  }, { name: 'Overall Experience', checked: true, mandatory: true }, {
    name: 'Current Address',
    checked: false,
    mandatory: false
  }, { name: 'Permanent Address', checked: false, mandatory: false }, {
    name: 'Emergency Contact',
    checked: false,
    mandatory: false
  }, { name: 'Blood Group', checked: false, mandatory: false }, 
  { name: 'Geo Location', checked: false, mandatory: false }, 
  { name: 'Base Location', checked: false, mandatory: false }, 
  { name: 'Employment Type', checked: false, mandatory: false },
  { name: 'Date of Birth', checked: false, mandatory: false },
  { name: 'Marital Status', checked: false, mandatory: false },
  { name: 'BA Experirnce', checked: false, mandatory: false },
  { name: 'Prior Experience', checked: true, mandatory: true },
  // { name: 'Visas Available', checked: false, mandatory: false },
    // { name: 'Passport Details', checked: false, mandatory: false }
  ];

  fieldMappings = {
    'Name': 'firstName',
    'Employee Id': 'employeeId',
    'Official Email': 'email',
    'Title': 'jobTitle',
    'Capability': 'capability',
    'Career Manager': 'careerManagerName',
    'Mobile No.': 'mobileNumber',
    'Date of Joining': 'joiningDate',
    'Overall Experience': 'previousEmployment',
    'Current Address': 'currentAddress',
    'Permanent Address': 'permanentAddress',
    'Emergency Contact': 'emergencyContact',
    'Blood Group': 'bloodGroup', 
    'Geo Location': 'geoLocation', 
    'Base Location': 'baseOfficeLocation', 
    'Employment Type': 'employmentType',
    'Date of Birth': 'dateOfBirth',
    'Marital Status': 'relationship_status',
  };
  selectedFields: string[] = [];

  constructor(public dialogRef: MatDialogRef<ExportEmployeeDetailsComponent>, public userService: UserService) {
  }

  ngOnInit(): void {
  }

  updateSelectedFields() {
    this.selectedFields = this.fields
      .filter(field => field.checked)
      .map(field => field.name);
  }

  generateReport() {
    this.generateButtonText = 'Please wait..';
    this.generateDisabled = true;
    this.userService.getAllUsersDetails().subscribe((res) => {
      console.log(res.body);
      const parsedData = res.body;
      const filteredData = this.filterData(parsedData);
      filteredData.forEach(item => {
        if (item['Blood Group']) {
          item['Blood Group'] = this.convertToTitleCase(item['Blood Group']);
        }
        if(item['Date of Birth']){
          item['Date of Birth'] = this.convertToTitleCase(item['Date of Birth'].substring(0, 10));
        }
        if(item['Marital Status']){
          item['Marital Status'] = this.convertToTitleCase(item['Marital Status']);
        }
        if (item['Geo Location']) {
          item['Geo Location'] = this.convertToTitleCase(item['Geo Location']);
        }
        if (item['Base Location']) {
          item['Base Location'] = this.convertToTitleCase(item['Base Location']);
        }
        if (item['Title']) {
          item['Title'] = this.convertToTitleCase(item['Title']);
        }
        if (item['Capability']) {
          item['Capability'] = this.convertToTitleCase(item['Capability']);
        }
        if(item['Employment Type']){
          item['Employment Type'] = this.convertToTitleCase(item['Employment Type']);
        }
        if(item['Date of Joining']){
          item['BA Experience'] = this.baExperienceCalculator(item['Date of Joining'].substring(0, 10)); 
          } 
        if (item['Overall Experience'] ){
          item['Prior Experience'] = this.priorExperienceCalculator(item['Overall Experience'],item['Date of Joining'].substring(0, 10));
        }  
        if (item['Overall Experience']) {
          item['Overall Experience']=this.calculateOverAllExperince(item['Overall Experience'], item['Date of Joining']);
        }           
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'All Employee Details.xlsx');
      this.generateButtonText = 'downloading...';
      this.generateDisabled = false;
      setTimeout(() => {
        this.matDialogClose();
      }, 1000);

    }, (e) => {
      this.generateDisabled = false;
      this.generateButtonText = 'Generate Report';
    });
  }
  
  convertToTitleCase(word) {
    return word.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  }
  filterData(parsedData: any) {
    const selectedFields = this.fields.filter(field => field.checked).map(field => this.fieldMappings[field.name]);
    const filteredData = parsedData.map(item => {
      item.firstName = item.firstName + ' ' + item.lastName;
      const filteredItem = {};
      selectedFields.forEach(field => {
        if (item[field]) {
          filteredItem[Object.keys(this.fieldMappings).find(key => this.fieldMappings[key] === field)] = item[field];
        }
      });
      return filteredItem;
    });

    return filteredData;
  }

  calculateOverAllExperince(previousEmployment, joiningDate) {
    let totalYears: number = 0;
    let totalMonths: number = 0;
    for (let r of previousEmployment) {
      const startDate = new Date(r.startDate);
      const endDate = new Date(r.endDate);

      let yearsDiff = endDate.getFullYear() - startDate.getFullYear();
      let monthsDiff = endDate.getMonth() - startDate.getMonth();

      if (monthsDiff < 0) {
        monthsDiff += 12;
        yearsDiff--;
      }

      totalYears += yearsDiff;
      totalMonths += monthsDiff;
    }

    const currentStartDate = new Date(joiningDate);
    const currentDate1 = new Date();
    let currentYears = currentDate1.getFullYear() - currentStartDate.getFullYear();
    let currentMonths = currentDate1.getMonth() - currentStartDate.getMonth();

    if (currentMonths < 0) {
      currentMonths += 12;
      currentYears--;
    }

    totalYears += currentYears;
    totalMonths += currentMonths;

    if (totalMonths >= 12) {
      const extraYears = Math.floor(totalMonths / 12);
      totalYears += extraYears;
      totalMonths -= extraYears * 12;
    }

    return `${totalYears} years and ${totalMonths} months`;
  }
  priorExperienceCalculator(previousEmployment,joiningDate) {
    let totalYears: number = 0;
    let totalMonths: number = 0;
    for (let r of previousEmployment) {
      console.log("Previous employement: ", previousEmployment);
      const startDate = new Date(r.startDate);
      const endDate = new Date(r.endDate);

      let yearsDiff = endDate.getFullYear() - startDate.getFullYear();
      let monthsDiff = endDate.getMonth() - startDate.getMonth();

      if (monthsDiff < 0) {
        monthsDiff += 12;
        yearsDiff--;
      }

      totalYears += yearsDiff;
      totalMonths += monthsDiff;
    }

    if (totalMonths >= 12) {
      const extraYears = Math.floor(totalMonths / 12);
      totalYears += extraYears;
      totalMonths -= extraYears * 12;
    }

    return `${totalYears} years and ${totalMonths} months`;
  }
  baExperienceCalculator(joiningDate) {
    
    const startDate = new Date(joiningDate);
    const currentDate = new Date();

    if (isNaN(startDate.getTime())) {
        return "0 years and 0 months";
    }

    let yearsDiff = currentDate.getFullYear() - startDate.getFullYear();
    let monthsDiff = currentDate.getMonth() - startDate.getMonth();

    if (monthsDiff < 0) {
        monthsDiff += 12;
        yearsDiff--;
    }

    if (monthsDiff >= 12) {
        const extraYears = Math.floor(monthsDiff / 12);
        yearsDiff += extraYears;
        monthsDiff -= extraYears * 12;
    }

    return `${yearsDiff} years and ${monthsDiff} months`;
  }
  matDialogClose() {
    this.dialogRef.close();
  }
}
