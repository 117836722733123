import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { OfficeAttendanceService } from 'src/app/services/office-attendance/office-attendance.service';
import { OfficeAttendanceImportDialogComponent } from '../../office-attendance-import-dialog/office-attendance-import-dialog.component';
import { BaseLocation, Departments, JobTitles } from 'src/app/services/user/user-enum';
import { ExportOfficeAttendanceComponent } from '../../export-office-attendance/export-office-attendance.component';
import * as moment from 'moment';
import { co } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-office-attendance-admin',
  templateUrl: './office-attendance-admin.component.html',
  styleUrls: ['./office-attendance-admin.component.scss']
})
export class OfficeAttendanceAdminComponent implements OnInit {

  isSpinnerActive: boolean = true;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  attendances: any = [];
  filteredAttendances: any = [];
  selectedDate: any;
  page: number = 1;
  pageSize: number = 20; 
  totalPages: number = 0;
  searchQuery: string = ''; 
  selectedDepartments: any = [];
  selectedJobTitles: any = [];
  selectedBaseLocations:string;
  sortConfig = { column: '', direction: 'asc' };
  jobTitleSearch: Array<string> = [];
  capabilitySearch: Array<string> = [];
  baseLocation:string;
  
  departments = [];
  jobTitles=[];
  baseLocations=[];
  highlightedDates: string[] = [];

  dateFilter = (date: Date | null): boolean => {
    const minDate = new Date(2025, 0, 1); // 01 Jan 2025
    return date !== null && date >= minDate // Restrict before minDate
  };
  
  // Highlight specific dates with a class
  highlightedDateClass = (date: Date): string => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return this.highlightedDates.includes(formattedDate) ? 'highlighted-date' : '';
  };
  

  constructor(private dialog: MatDialog,
              private officeAttendanceService: OfficeAttendanceService) {}

  ngOnInit(): void {
    this.setDate();
    this.setBaseLocations();
    this.getAllAttendanceRecords();
    for (const department of Object.values(Departments)) {
      if (isNaN(Number(department))) {
        this.departments.push(department);
      }
    }

    for (const jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle))) {
        this.jobTitles.push(jobTitle);
      }
    }
    for (const baseLocation of Object.values(BaseLocation)) {
      if (isNaN(Number(baseLocation))) {
        this.baseLocations.push(baseLocation);
      }
    }
  }

  setDate() {
    const today = new Date();
    this.selectedDate = new Date(today);
    this.selectedDate.setDate(today.getDate() - 1);
    this.selectedDate = this.formatDate(this.selectedDate);
  }
  setBaseLocations(){
    this.selectedBaseLocations = 'Pune';
    this.baseLocation=this.getBaseLocationTitleKeyByValue(this.selectedBaseLocations.toUpperCase());
  }
  onDateChange(event: any): void {
    this.selectedDate = event.value;
    this.selectedDate = this.formatDate(this.selectedDate);
    this.page = 1; 
    this.getAllAttendanceRecords();
  }

  onSearchInput(value: string): void {
    this.searchQuery = value.trim();
    this.page = 1; 
    this.getAllAttendanceRecords();
  }

  onFiltreChange(): void {
    this.selectedDepartments = this.capabilitySearch.map(capability => this.getDepartmentKeyByValue(capability));
    this.selectedJobTitles = this.jobTitleSearch.map(jobTitle => this.getJobTitleKeyByValue(jobTitle));
    this.selectedBaseLocations = this.capitalizeFirstLetter(
      this.getBaseLocationTitleKeyByValue(this.baseLocation)
    );
    this.page = 1; 
    this.getAllAttendanceRecords();
  }
  capitalizeFirstLetter(value: string): string {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
  getDepartmentKeyByValue(value: string): string | undefined {
    return Object.keys(Departments).find(key => Departments[key] === value);
  }

  getJobTitleKeyByValue(value: string): string | undefined {
    return Object.keys(JobTitles).find(key => JobTitles[key] === value);
  }
  getBaseLocationTitleKeyByValue(value: string): string | undefined {
    return Object.keys(BaseLocation).find(key => BaseLocation[key] === value);
  }

  onPageChange(newPage: number): void {
    this.page = newPage;
    this.getAllAttendanceRecords();
  }

  getAllAttendanceRecords() {
    this.isSpinnerActive = true;
    this.attendances = [];
    this.filteredAttendances = [];

    this.officeAttendanceService.getAllAttendanceRecords(
      this.selectedDate,
      this.page - 1,
      this.pageSize,
      this.searchQuery,
      this.selectedDepartments,
      this.selectedJobTitles,
      this.selectedBaseLocations
    ).subscribe(res => {
      this.attendances = res.body?.attendanceData?.content || [];
      this.filteredAttendances = [...this.attendances];
      this.filteredAttendances.forEach(element => {
        if (element.userThumbnailImage !== '') {
          element.userThumbnailImage =
            'data:image/png;base64,' + element.userThumbnailImage;
        }
      });
      this.totalPages = res.body?.attendanceData?.totalPages || 0
      this.isSpinnerActive = false;
      if (res.body?.recordUploadedDates) {
        
        this.highlightedDates = res.body?.recordUploadedDates;
      
      }
      else{
        this.highlightedDates = [];
      }
    }, () => {
      this.isSpinnerActive = false;
    });
    
  }
  checkBaseLocation(attendanceData){
    for(let i=0;i<attendanceData.length;i++){
      if (attendanceData[i].baseLocation !== this.baseLocation){
        return false;
      }
    }
    return true;
  }
  openExportOptions(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.height = "40%";
    dialogConfig.panelClass = "activate-employee"
    const dialogRef = this.dialog.open(ExportOfficeAttendanceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => { });
  }

  addBulkAttendanceRecords() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '40%';
    dialogConfig.height = '35%';
    dialogConfig.data = {hideReupload: false,isReuploading:false,headingText:"Import Office Attendance"};

    const dialogRef = this.dialog.open(OfficeAttendanceImportDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getAllAttendanceRecords();
    });
  }

  formatMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }


  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  ifOverflow(e) {
    return e.scrollWidth > e.clientWidth;
  }
}
