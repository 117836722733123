import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AboutComponent } from './about/about.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProfessionalSkillsComponent } from './professional-skills/professional-skills.component';
import { EmploymentHistoryComponent } from './employment-history/employment-history.component';
import { EducationalQualificationsComponent } from './educational-qualifications/educational-qualifications.component';
import { CertificationsComponent } from './certifications/certifications.component';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { Education } from 'src/app/models/educationdetails';
import { EducationDetailsService } from 'src/app/services/education-details/education-details.service';
import { CertificationService } from 'src/app/services/certification/certification.service';
import { SkillService } from 'src/app/services/skill/skill.service';
import { PreviousEmploymentService } from 'src/app/services/previousEmployment/previous-employment.service';
import { PreviousEmployment } from 'src/app/models/previousEmployment';
import { Skill } from 'src/app/models/skill';
import { Certificate } from 'src/app/models/certificate';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { PositionDetailsComponent } from './position-details/position-details.component';
import { AuthoritiesConstants, EventTabClosed, JobTitles } from 'src/app/services/user/user-enum';
import { Subscription } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';


@Component({
  selector: 'app-spot-light',
  templateUrl: './spot-light.component.html',
  styleUrls: ['./spot-light.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({ transform: 'translateY(50%)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
    ])
  ]
})
export class SpotLightComponent implements OnInit {
  @Output() dummyfunc = new EventEmitter();
  userDTO: UserDTO[] = [];
  positionHistory: string[] = [];
  selfLoggedin = false;
  userid: string = null;
  userdto = new UserDTO();
  about: string;
  educationdetailsdto: Education[] = [];
  skilldto: Skill;
  certificatedto: Certificate[] = [];
  preempdto: PreviousEmployment[] = [];
  preempexp: Object = {};
  isVisible = false;
  jobTitle = JobTitles;
  overall = 0;
  skills_loading = false;
  employment_history_loading = false;
  educational_qualifications_loading = false;
  certifications_loading = false;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  userAbout: string = '';
  allowEditToOthers = false;
  dataSubscription :Subscription;
  totalWorkedYears=0;
  totalWorkedMonths=0;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private userService: UserService,
    private userDetailService: UserDetailsService,
    private educationdetailsservice: EducationDetailsService,
    private skillservice: SkillService,
    private certificateservice: CertificationService,
    private empservice: PreviousEmploymentService,
    private route: ActivatedRoute,
    private feedbackService: FeedbackService,
  ) {
   
  }
 
  ngOnInit(): void {
   this.refreshOnSearchOfEmployee();
  }
  refreshOnSearchOfEmployee()
  {
    this.dataSubscription = this.feedbackService.data$.subscribe((data) => {
      this.userid = data;
        this.initiateComponent();
        this.getAbout();
        this.getProfessionalSkills();
        this.getEducationalQualification();
        this.getPreviousEmployeement();
        this.getCertifications();
        this.getUserData();
        this.setPostionHistory();      
    });
  }
  initiateComponent() {
    this.getUserId()
    this.jobTitle = JobTitles;
  }
  getUserId()
  {
      // To check if user/HR/Admin can edit the details
      if (this.userid == null) {
        this.userid = this.route.snapshot.paramMap.get('id')
          ? this.route.snapshot.paramMap.get('id')
          : this.userDetailService.getUserDetails().id;
          this.selfLoggedin =
          this.userid == this.userDetailService.getUserDetails().id;
        this.allowEditToOthers =
          this.userid == this.userDetailService.getUserDetails().id ||
          this.userDetailService.hasRole([
            this.getKeyByValue(AuthoritiesConstants.ROLE_HUMAN_RESOURCE),
            this.getKeyByValue(AuthoritiesConstants.ROLE_SUPER_ADMIN).toString(),
            this.getKeyByValue(AuthoritiesConstants.ROLE_ADMIN).toString(),
          ]);
      } else {
        this.selfLoggedin =
          this.userid == this.userDetailService.getUserDetails().id;
        this.allowEditToOthers =
          this.userid == this.userDetailService.getUserDetails().id ||
          this.userDetailService.hasRole([
            this.getKeyByValue(AuthoritiesConstants.ROLE_HUMAN_RESOURCE),
            this.getKeyByValue(AuthoritiesConstants.ROLE_SUPER_ADMIN).toString(),
            this.getKeyByValue(AuthoritiesConstants.ROLE_ADMIN).toString(),
          ]);
      }
  }
  checkSpinner() {
    this.dummyfunc.emit(16.5);
  }
  getKeyByValue(value: string): string | undefined {
    return Object.keys(AuthoritiesConstants).find(key => AuthoritiesConstants[key] === value);
}
  getUserData() {
    this.userService.getUserByID(this.userid).subscribe(
      (res) => {
        this.userDTO = res.body;
        this.checkSpinner();
      },
      (e) => {
        this.checkSpinner();
      }
    );
  }
  setPostionHistory() {
    this.positionHistory = [];
    this.userService.getpositionHistroy(this.userid).subscribe((res) => {
      if (res.body != null) {
        this.positionHistory = res.body.positionHistory;
      }
    });
  }
  editPositionDetails() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'position-details';
    dialogConfig.data = this.userDTO;
    const dialogRef = this.dialog
      .open(PositionDetailsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == 'success') {
          this.positionHistory.length = 0;
          this.setPostionHistory();
        }
      });
  }
  getAbout() {
    this.userAbout = '';
    this.userService.getUserAbout(this.userid).subscribe(
      (res) => {
        if (res.body.about == undefined || res.body.about == null) {
          this.userAbout = '';
        } else {
          this.userAbout = res.body.about;
        }
        this.checkSpinner();
      },
      (e) => {
        this.checkSpinner();
      }
    );
  }
  getProfessionalSkills() {
    this.skilldto;
    this.skillservice
      .getskillByUserIdSubscribe(this.userid)
      .subscribe((res) => {
        this.skills_loading = true;
        this.skilldto = this.skillservice.setSkillResponse(res.body);
        this.checkSpinner();
      },
        (e) => {
          this.checkSpinner();
        }
      );
  }
  // Function to add spaces between elements of the array
  spaceOutArray(arr) {
    if (arr && arr.length > 0) {
      return arr.join(', ');
    } else {
      return arr;
    }
  }
  getPreviousEmployeement() {
    this.preempdto = [];
    this.empservice.getprevempByUserIdSubscribe(this.userid).subscribe(
      (res) => {
        this.employment_history_loading = true;
        this.preempdto = this.empservice.setPrevempResponse(res.body).reverse();
        for (const r of res.body) {
          this.preempexp[r.id] = this.processDate(r);
        }
        this.checkSpinner();
      },
      (e) => {
        this.checkSpinner();
      }
    );
  }
  getEducationalQualification() {
    this.educationdetailsdto = [];
    this.educationdetailsservice
      .getskillByUserIdSubscribe(this.userid)
      .subscribe(
        (res) => {
          this.educational_qualifications_loading = true;
          this.educationdetailsdto =
            this.educationdetailsservice.setEducationResponse(res.body).reverse();
          this.checkSpinner();
        },
        (E) => {
          this.checkSpinner();
        }
      );
  }
  getCertifications() {
    this.certificatedto = [];
    this.certificateservice.getCertByUserIdSubscribe(this.userid).subscribe(
      (res) => {
        this.certifications_loading = true;
        this.certificatedto = this.certificateservice.setCertResponse(res.body).reverse();
        this.checkSpinner();
      }, (e) => {
        this.checkSpinner();
      });
  }
  isExpired(date: Date): boolean {
    const currentDate = new Date();
    const validUptoMillis = new Date(date).getTime();
    const currentMillis = currentDate.getTime();
    return currentMillis > validUptoMillis;
  }
  editAboutDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '60%';
    dialogConfig.panelClass = 'about-class';
    const aboutData = [this.userAbout, this.userid];
    dialogConfig.data = aboutData;
    const dialogRef = this.dialog
      .open(AboutComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getAbout();
        }
      });
  }
  editProfessionalskillsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.panelClass = 'professional-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(ProfessionalSkillsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getProfessionalSkills();
        }
      });
  }
  editEmploymentHistoryDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'employment-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(EmploymentHistoryComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getPreviousEmployeement();
        }
      });
  }
  editEducationalQualificationsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'education-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(EducationalQualificationsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getEducationalQualification();
        }
      });
  }
  editCertificationsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = '78vh';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'certifications-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(CertificationsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == EventTabClosed.Closed) {
          this.getCertifications();
        }
      });
  }
  // To calculate user experience in Employeement History
  processDate(i) {
    let experience;
    if (i.startDate && i.endDate) {
      const startDate = new Date(i.startDate);
      const endDate = new Date(i.endDate);
      const diff = endDate.getTime() - startDate.getTime();
      const days = diff / (1000 * 60 * 60 * 24);
      this.overall += days;
      const year = this.getYearFromDays(days);
      const months = this.getMonthFromDays(days);
      experience = year > 0 ? year + ' Year ' : '';
      experience += months == 1 ? months + ' Month' : '';
      experience += months > 1 ? months + ' Months' : '';
      if (months < 1 && year < 1) {
        experience = '< 1 Month';
      }
    }
    return experience;
  }
  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30);
  }
  getYearFromDays(days) {
    return Math.floor(days / 365);
  }
  calculateExperience(){
    let experience;
    const year = this.getYearFromDays(this.overall);
    const months = this.getMonthFromDays(this.overall);
    if (year > 0) {
      experience = year > 0 ? year + ' years ' : '0 years';
      experience += months >= 1 ? months + ' months' : '0 months'; 
    }else{
      experience='< 1 month'
    }
    return experience;
  }
}
