import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { Departments, ExcelSheetName, GeoLocation, JobTitles } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { DeactivateEmployeeComponent } from '../../employee/employee-activation/deactivate-employee/deactivate-employee.component';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { AddNewEmployeeCardComponent } from '../add-new-employee-card/add-new-employee-card.component';
import { TooltipPosition } from '@angular/material/tooltip';
import { formatDate } from '@angular/common';
import { ExportEmployeeDetailsComponent } from '../../employee/export-employee-details/export-employee-details.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { OfficeAttendanceImportDialogComponent } from '../../home/office-attendance/office-attendance-import-dialog/office-attendance-import-dialog.component';
import { debounceTime } from 'rxjs/operators';
import { debounce } from 'lodash';


@Component({
  selector: 'app-active-employees',
  templateUrl: './active-employees.component.html',
  styleUrls: ['./active-employees.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
    ])
  ]
})
export class ActiveEmployeesComponent implements OnInit {
  activeUser: UserDTO[]
  departments = [];
  jobTitles = [];
  geoLocation = [];
  department = [];
  jobTitle = [];
  page: number = 1;
  isSpinner: boolean = true;
  filteredUsers: UserDTO[];
  searchForm: FormGroup;
  
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  currentSortName: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  currentSortEmployeeId: { column: string, direction: 'asc' | 'desc' } = { column: 'employeeId', direction: 'asc' };
  currentSortJobTitle: { column: string, direction: 'asc' | 'desc' } = { column: 'jobTitle', direction: 'asc' };
  currentSortDepartment: { column: string, direction: 'asc' | 'desc' } = { column: 'department', direction: 'asc' };
  userNameIDMapping = {};
  userIdNameMapping = {};

  pageSize: number = 20; 
  totalPages: number = 0;
  searchQuery: string = ''; 
  selectedDepartments: any = [];
  selectedJobTitles: any = [];
  selectedGeoLocations:any=[];
  capabilitySearch: Array<string> = [];
  jobTitleSearch: Array<string> = [];
  geoLocationSearch: Array<string> = [];
  searchControl = new FormControl('');

  // Add this property to your component class
  currentSort: { column: string, direction: 'asc' | 'desc' } = { column: 'name', direction: 'asc' };
  constructor(private userService: UserService, private dialog: MatDialog, private router: Router
  ) {
    this.applySearch = debounce(this.applySearch.bind(this), 1000);
  }
  ngOnInit(): void {
    this.createUserIdNameMapping();
    this.processEnumValuesToArray();
    this.getActiveUser();
    this.searchControl.valueChanges.subscribe(value => {
      this.applySearch(value);
    });
  }

  onFiltreChange(): void {
    this.selectedDepartments = this.capabilitySearch.map(capability => this.getDepartmentKeyByValue(capability));
    this.selectedJobTitles = this.jobTitleSearch.map(jobTitle => this.getJobTitleKeyByValue(jobTitle));
    this.selectedGeoLocations = this.geoLocationSearch.map(geoLoc => this.getGeoLocationKeyByValue(geoLoc));
    this.page = 1; 
    this.getActiveUser();
  }
  onPageChange(newPage: number): void {
    this.page = newPage;
    this.getActiveUser();
  }
  createUserIdNameMapping(): void {
    this.userService.getAllUserForDropDown().subscribe((usrData) => {
      usrData.body.forEach((element) => {
        this.userNameIDMapping[
          element.firstName + ' ' + element.lastName + '(' + element.employeeId + ')'
        ] = element.userID;
        this.userIdNameMapping[
          element.userID
        ] = element.firstName + ' ' + element.lastName + '(' + element.employeeId + ')';
      }
      ),(err)=>{
        console.log('Unable to load users: '+err);
      };
    });
  }


  getActiveUser() {
    this.isSpinner = true;
  
    this.userService.getActiveEmployee(
      this.page - 1, // Backend expects 0-based index
      this.pageSize,
      this.searchQuery,
      this.selectedDepartments,
      this.selectedJobTitles,
      this.selectedGeoLocations
    ).subscribe(
      (res) => {
        console.log(res);
  
        // Extract user list correctly
        this.filteredUsers = res.body?.userData?.content || [];
  
        // Ensure images are properly formatted
        this.filteredUsers.forEach((element) => {
          if (element.userImageThumbnail && element.userImageThumbnail !== '') {
            element.userImageThumbnail =
              'data:image/png;base64,' + element.userImageThumbnail;
          }
        });
  
        // Corrected totalPages calculation
        this.totalPages = res.body?.userData?.totalPages || 
                          Math.ceil(res.body?.userData?.totalElements / this.pageSize) || 1;
  
        this.isSpinner = false;
      },
      (error) => {
        console.error("Error fetching active users:", error);
        this.isSpinner = false;
      }
    );
  }
  
  

  ifOverflow(e) {
    return e.scrollWidth > e.clientWidth;
  }
  editEmployeeDialog(empData) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = '85%';
    dialogConfig.data = [empData, "active-user"];

    const dialogRef = this.dialog.open(AddNewEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.getActiveUser();
      this.searchQuery = '';
      this.capabilitySearch = [];
      this.jobTitleSearch = [];
      this.geoLocation = [];
    });
  }
  deactivateEmployee(empData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.height = '200px';
    dialogConfig.data = [empData, "Deactivate"];
    dialogConfig.panelClass = 'deactivate-employee';
    const dialogRef = this.dialog.open(
      DeactivateEmployeeComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      this.getActiveUser();
    });
  }
  viewEmployee(empDetail) {
    this.router.navigate([`/employee/employee-profile/${empDetail.id}`]);
  }
  applySearch(value: string): void{
    this.searchQuery = value.trim();
    this.page = 1; 
    this.getActiveUser();
  }

  
  processEnumValuesToArray() {
    for (const jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle))) {
        this.jobTitles.push(jobTitle);
      }
    }

    for (const department of Object.values(Departments)) {
      if (isNaN(Number(department))) {
        this.departments.push(department);
      }
    }
    for (const geoLocation of Object.values(GeoLocation)) {
      if (isNaN(Number(geoLocation))) {
        this.geoLocation.push(geoLocation);
      }
    }
  }
  getDepartmentKeyByValue(value: string): string | undefined {
    return Object.keys(Departments).find(key => Departments[key] === value);
  }
  getJobTitleKeyByValue(value: string): string | undefined {
    return Object.keys(JobTitles).find(key => JobTitles[key] === value);
  }
  getGeoLocationKeyByValue(value: string): string | undefined {
    return Object.keys(GeoLocation).find(key => GeoLocation[key] === value);
  }

  sortTable(column: string): void {
    switch (column) {
      case 'name':
      case 'employeeId':
      case 'jobTitle':
      case 'department':
      case 'carrierManagerName':
      case 'email':
        this.sortByColumn(column);
        break;
    }
  }

  private sortByColumn(column: string): void {
    const currentSort = this.getCurrentSortForColumn(column);

    if (currentSort.column === column) {
      currentSort.direction = currentSort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      currentSort.column = column;
      currentSort.direction = 'asc';
    }

    this.updateCurrentSortForColumn(column, currentSort);

    this.filteredUsers.sort((a, b) => {
      const aValue = a[column] ? a[column].toLowerCase() : null;
      const bValue = b[column] ? b[column].toLowerCase() : null;

      if (aValue === null && bValue === null) {
        return 0;
      } else if (aValue === null) {
        return 1;
      } else if (bValue === null) {
        return -1;
      } else {
        return currentSort.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });
  }

  private getCurrentSortForColumn(column: string): { column: string, direction: 'asc' | 'desc' } {
    switch (column) {
      case 'name':
        return this.currentSortName;
      case 'employeeId':
        return this.currentSortEmployeeId;
      case 'jobTitle':
        return this.currentSortJobTitle;
      case 'department':
        return this.currentSortDepartment;
      default:
        return { column: '', direction: 'asc' };
    }
  }

  private updateCurrentSortForColumn(column: string, currentSort: { column: string, direction: 'asc' | 'desc' }): void {
    switch (column) {
      case 'name':
        this.currentSortName = currentSort;
        break;
      case 'employeeId':
        this.currentSortEmployeeId = currentSort;
        break;
      case 'jobTitle':
        this.currentSortJobTitle = currentSort;
        break;
      case 'department':
        this.currentSortDepartment = currentSort;
        break;
    }
  }
  exportToExcel(): void {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.height = "69%";
    dialogConfig.panelClass = "activate-employee"
    const dialogRef = this.dialog.open(ExportEmployeeDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => { });
  }
  getDepartmentValueByKey(key: string): any {
    return Departments[key];
  }
  getJobTitleValueByKey(key: string): any {
    return JobTitles[key];
  }
  getGeoLocationValueByKey(key: string): any {
    return GeoLocation[key];
  }
  uploadSlackIds(){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '40%';
      dialogConfig.height = '30%';
      dialogConfig.data = {hideReupload: true,isReuploading:false,headingText:"Import Slack ids"}
    
      const dialogRef = this.dialog.open(OfficeAttendanceImportDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result) => {});
      
  }
}