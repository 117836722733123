import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class FeedbackgroupUrlService {

  //and integrate FE with http://localhost:8080/api/feedbacks/getAllEmployeesCalibratedScore/{year}
  private readonly getAllEmployeesCalibratedScore = '/getAllEmployeesCalibratedScore'
  private readonly feedbackGroupDetailsBaseApi = '/api/feedbacks';
  private readonly probationFeedbackGroupDetailsBaseApi = '/api/probation/feedbacks';
  private readonly feedbackGroupDetailBaseApi = '/api/feedback';
  private readonly getFeedbackSummeryBaseApi = '/api/feedback/getOrphanSummary';
  private readonly probationfeedbackGroupDetailBaseApi = '/api/probation/feedback';
  private readonly probationFeedbackGroupDetailBaseApi = '/api/probation/feedback';
  private readonly feedbackGroupBulkExport = '/api/feedbacks/bulk_export_feedback'
  private readonly probationFeedbackGroupBulkExport = '/api/probation/feedbacks/bulk_export_feedback'
  private readonly user = '/user';
  private readonly submit = '/submit';
  private readonly decline = '/declineFeedback';
  private readonly save = '/save';
  private readonly userId_param = 'userId';
  private readonly status = 'status';
  private readonly myReportee = '/myReportee';
  private readonly year = 'year';
  private readonly manageFeedbacks = 'manageFeedbacks';
  private readonly calibrateScore = 'calibrateScore'
  private readonly calibratedScore = 'calibratedScore'
  private readonly weight = 'weight'
  private readonly weightage = 'weightage'
  private readonly deleteFeedbackGroup = 'deleteFeedbackGroup'
  private readonly closeFeedback = 'closeFeedback'
  private readonly cmReport = 'getCmReport'
  private readonly assignRealtimeFeedback = '/assignRealTimeFeedback/'
  private readonly feedbackSummeryBaseApi = '/api/feedback/submitOrphanSummary';
  constructor() { }
  getBaseUrl() {
    return this.feedbackGroupDetailsBaseApi
  }
  updateUrl(id, weightage) {
    return this.feedbackGroupDetailsBaseApi + '/' + this.manageFeedbacks + '/' + id + '?' + this.weightage + '=' + weightage
  }
  updateFeedback(groupId)
  {
  return this.feedbackGroupDetailBaseApi+"/updateFeedback/"+groupId;
  }
  delete(id) {
    return this.feedbackGroupDetailsBaseApi + '/' + this.deleteFeedbackGroup + '/' + id;
  }
  closeFeedBack(userId, year) {
    return this.feedbackGroupDetailsBaseApi + '/' + this.closeFeedback + '/' + userId + '/' + year
  }
  closeFeedBackSaveNote(userId, year, saveAsDraft) {
    return this.feedbackGroupDetailsBaseApi + '/' + 'updateFeedbackSummaryAndCloseConditionally' + '/' + userId + '/' + year + '/' + saveAsDraft
  }
  
  closeProbationFeedBackSaveNote(userId, year, saveAsDraft) {
    return this.probationFeedbackGroupDetailsBaseApi + '/' + 'updateFeedbackSummaryAndCloseConditionally' + '/' + userId + '/' + year + '/' + saveAsDraft
  }

  getFeedbackOverallstatus(userId, year) {
    return this.feedbackGroupDetailsBaseApi + '/' + this.status + '/' + userId + '/' + year
  }
  
  getProbationFeedbackOverallstatus(userId, year) {
    return this.probationFeedbackGroupDetailsBaseApi + '/' + this.status + '/' + userId 
  }
  getUserUrl(userId) {
    return this.feedbackGroupDetailsBaseApi + this.user + '?' + this.userId_param + '=' + userId
  }
  getByFieldUrl(field) {
    return this.feedbackGroupDetailsBaseApi + this.getByField(field);
  }
  getfeedbackbyuserandyear(userId, year) {
    return this.feedbackGroupDetailsBaseApi + '/' + userId + '/' + year
  }
  getProbationeedbackbyuser(userId, year) {
    return this.probationFeedbackGroupDetailsBaseApi + '/get'+'/'+  userId 
  }
  getAllFeedback(year) {
    return this.feedbackGroupBulkExport + '/' + year
  }
  getAllProbationFeedback(year) {
    return this.probationFeedbackGroupBulkExport
  }
  getdirectReporteebyUserId(userId, year) {
    return this.feedbackGroupDetailsBaseApi + '/' + 'directReportees/' + userId + '?' + 'year' + '=' + year
  }
  getdirectreporteebyyear(year) {
    return this.feedbackGroupDetailsBaseApi + this.myReportee + '?' + 'year' + '=' + year
  }
  getdirectreporteebyyearforHr(queryParams) {
    return this.feedbackGroupDetailsBaseApi + this.getAllEmployeesCalibratedScore + queryParams
  }

  getProbationdirectreporteebyyearforHr(year) {
    return this.probationFeedbackGroupDetailsBaseApi + this.getAllEmployeesCalibratedScore 
  }
  updateCalibratedScore(groupId) {
    return this.feedbackGroupDetailsBaseApi + '/updateCalibratedScore/' + groupId;
  }
  private getByField(field) {
    return '/' + field;
  }

  submitFeedback(id) {
    return this.feedbackGroupDetailBaseApi + this.submit + '/' + id
  }
  submitProbationFeedback(id) {
    return this.probationFeedbackGroupDetailBaseApi + this.submit + '/' + id
  }
  declineFeedback(id) {

    return this.feedbackGroupDetailBaseApi + this.decline + '/' + id

  }
  saveFeedback(id) {
    return this.feedbackGroupDetailBaseApi + this.save + '/' + id

  }
  getFeedbackUrl(id) {
    return this.feedbackGroupDetailBaseApi + '/' + id
  }
  getFeedbackSummeryUrl(id) {
    return this.getFeedbackSummeryBaseApi + '/' + id
  }
  editRealTimeFeedbackUrl(id) {
    return this.feedbackGroupDetailBaseApi + '/editRealTimeFeedback/' + id
  }
  getProbationFeedbackUrl(id) {
    return this.probationFeedbackGroupDetailBaseApi + '/' + id
  }
  getFeedbackGroupById(id) {
    return this.feedbackGroupDetailsBaseApi + '/' + id
  }
  reopendFeedback(userid, year) {

    return this.feedbackGroupDetailsBaseApi + '/' + 'openFeedback' + '/' + userid + '/' + year;
  }
  reopendProbationFeedback(userid, year) {

    return this.probationFeedbackGroupDetailsBaseApi + '/' + 'openFeedback' + '/' + userid;
  }
  sendRemainder(feedbackId) {
    return this.feedbackGroupDetailBaseApi + '/sendReminder/' + feedbackId
  }

  sendProabtionRemainder(feedbackId) {
    return this.probationfeedbackGroupDetailBaseApi + '/sendReminder/' + feedbackId
  }
  reSubmit(feedbackId) {
    return this.feedbackGroupDetailBaseApi + '/' + 'reOpenSubmittedFeedback/' + feedbackId
  }
  reProbationSubmit(feedbackId) {
    return this.probationfeedbackGroupDetailBaseApi + '/' + 'reOpenSubmittedFeedback/' + feedbackId
  }
  getAllCmReport(geoLocation, year) {

    return this.feedbackGroupDetailsBaseApi + "/" + this.cmReport + "/" + geoLocation + "/" + year;
  }

  setRealTimeFeedback(groupId){
    return this.feedbackGroupDetailsBaseApi + this.assignRealtimeFeedback + groupId 
  }
  feedbackSummerySubmit(feedbackId){
    return this.feedbackSummeryBaseApi+'/'+feedbackId; 
  }
}

