import { Component, OnInit } from '@angular/core';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProfileDetailComponent } from '../profile-detail/profile-detail.component';
import { GeoLocation } from 'src/app/services/user/user-enum';
import { ToastrService } from 'ngx-toastr';
import { value } from '../../edit-app/global.model';
import { debounce } from 'lodash';
import { FormControl} from '@angular/forms';

@Component({
  selector: 'app-calibrated-report',
  templateUrl: './calibrated-report.component.html',
  styleUrls: ['./calibrated-report.component.scss'],
})
export class CalibratedReportComponent implements OnInit {
  selected: number;
  nodata: Boolean = true;
  years = [];
  userDtao = [];
  selectedHrLocation;
  locationArray = [];
  searchText: string;
  filteredList = [];
  searchQuery:string='';
  searchControl = new FormControl('');
  status:string="All";
  selectedDate;
  location;
  isAll;
  isOpen;
  isClose;

  constructor(
    private feedbackgroupservice: NewFeedbackGroupService,
    private dialogue: MatDialog,
    private toastService: ToastrService
  ) {
    this.applyFilter=debounce(this.applyFilter.bind(this),300);
  }

  ngOnInit(): void {
    this.getCalendarNewDate();
    this.processEnumValuesToArray();
    this.selectedHrLocation = this.locationArray.includes("India") ? "India" : this.locationArray[0];
    this.location = this.selectedHrLocation;
    this.searchControl.valueChanges.subscribe((value) => {
      console.log("Search Input Changed:", value); 
      this.applyFilter(value);
    });
    this.getAllEmployeeForCalibratedScore();
  }
  ifOverflow(e) {
    return e.scrollWidth > e.clientWidth;
  }
  processEnumValuesToArray() {
      for (const geoLocation of Object.values(GeoLocation)) {
        if (isNaN(Number(geoLocation))) {
          this.locationArray.push(geoLocation);
        }
      }
    }

  getCalendarNewDate() {
    this.selected = new Date().getFullYear();
    for (let year = this.selected; year >= 2022; year--) {
      this.years.push(year);
    }
    this.selectedDate=this.selected;
  }

  onViewReportee(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '95%';
    dialogConfig.maxWidth = '95%';
    dialogConfig.data = {
      userId: id,
      viewer: 'HR',
    };
    const dialogRef = this.dialogue.open(ProfileDetailComponent, dialogConfig);
  }

  getAllEmployeeForCalibratedScore() {
      this.feedbackgroupservice
        .getdirectreporteebyyearforHr(this.selectedDate,this.searchQuery,this.selectedHrLocation,this.status)
        .subscribe({
          next: (res) => {
            this.filteredList=res.body;
            this.userDtao=res.body;
          },
          error: (err) => {
            console.error('Error fetching employees:', err);
            this.toastService.error('Failed to fetch employee data. Please try again.');
          },
        });
  }
  applyFilter(value:string): void {
    if (value === null || value === undefined) {
      return; // Avoid processing undefined values
    }
    this.searchQuery = value.trim();
    console.log("Filtered Query:", this.searchQuery);
    this.onFiltreChange();
  }  
  downloadExcel() {
    const XLSX = require('xlsx');

    const formattedData = this.userDtao.map((item) => ({
      'Employee Name': `${item.firstName} ${item.lastName}`,
      'Feedback Overall Score': item.totalScore,
      'Employee ID': item.employeeId,
      'Job Title': item.jobTitle,
      'Feedback Status': item.closed ? 'Closed' : 'Open',
      'Geo Location': item.userLocation,
      Capability: item.capability.replaceAll('_', ' '),
      'Career Manager': item.careerManager,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Feedback Data');

    XLSX.writeFile(
      workbook,
      'FeedbackData-' + this.selected + `-${this.selectedHrLocation}` + '.xlsx'
    );
  }

  Open() {
    this.status = "Open";
    this.onFiltreChange();
    this.isOpen=true;
    this.isClose=false;
    this.isAll=false;
  }

  Close() {
    this.status = "Close"; 
    this.onFiltreChange(); 
    this.isClose=true;
    this.isOpen=false;
    this.isAll=false;
  }
  All() {
    this.status = "All";
    this.onFiltreChange();
    this.isAll=true;
    this.isOpen=false;
    this.isClose=false;
  }
  onFiltreChange(){
    this.selectedDate=this.selected;
    this.selectedHrLocation=this.location;
    this.getAllEmployeeForCalibratedScore();
  }
  getGeoLocationKeyByValue(value: string): string | undefined {
    return Object.keys(GeoLocation).find(key => GeoLocation[key] === value);
  }

}
