import { Component, Input, OnInit } from '@angular/core';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ProjectService } from 'src/app/services/project.service';
import { TalentService } from 'src/app/services/talent/talent.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { JsonFormComponent } from '../json-form/json-form.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
    ])
  ]
})
export class HomeComponent {
  hasRoleBased: boolean=false;
  hasCmRights: boolean = false;
  hasBaseLocationPune: boolean = false;
  hasBaseLocationBangalore: boolean = false;

  constructor(
    private userDetailsService: UserDetailsService,
    private talentservice: TalentService,
    private projectservice: ProjectService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private feedbackService: FeedbackService,
    private user: UserService,
    private dialog: MatDialog,
    private formSchema: FormsSchemaService,
    private toaster: ToastrService
  ) {
    console.log(this.userDetailsService.getUserDetails().baseLocation)
    this.hasBaseLocationPune = this.userDetailsService.getUserDetails().baseLocation.toLowerCase() == 'pune';
    this.hasBaseLocationBangalore=this.userDetailsService.getUserDetails().baseLocation.toLowerCase() == 'bangalore';
  }

  ngOnInit(): void {
    // this.getProject();
    //If logged in user is Admin, then set hasRoleBased to true
    // this.hasRoleBased = this.userDetailsService.hasRole(['ROLE_ADMIN']);
    // this.hasCmRights = this.userDetailsService.hasRole(['ROLE_CAREER_MANAGER']);
    this.verifyAuthorities();
    this.checkFeedbackSubmitRequest();
  }

  checkFeedbackSubmitRequest() {
    const currentUrl = this.router.url; 
    const pattern = /^\/feedback\/\w+$/;

    if (pattern.test(currentUrl)) {
      this.activatedRoute.paramMap.subscribe(params => {
       let feedbackId = params.get('id');
        if (feedbackId) {
          this.OpenJSonForm(feedbackId);
          this.OpenProbationJSonForm(feedbackId);

        } 
      });
    }
  }

  async OpenJSonForm(feedbackId) {
    await this.feedbackService.getFeedBackByIdAsync(
      feedbackId
    );
    if (await this.feedbackService.feedbackType==='PROBATION_FEEDBACK'){
      this.OpenProbationJSonForm(feedbackId);
    }
    if(await this.feedbackService.feedbackSubmissionStatus==='SUBMITTED'){
      this.toaster.warning('Feedback Already Submitted');
      return;
    }
    var resOfFeedBackAfterParse = await this.feedbackService
      .resOfFeedBackAfterParse;
    var resOfFeedBackGroupAfterParse = await this.feedbackService
      .resOfFeedBackGroupAfterParse;
    var timeFrame = resOfFeedBackGroupAfterParse.year;
    await this.user.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
    var assinedUserAfterParse = await this.user.assinedUserAfterParse;
    var assinedManagerUseAfterParse = await this.user
      .assinedManagerUseAfterParse;
    await this.formSchema.getFormSchemaByRoleAndJobTitleAsync(
      resOfFeedBackAfterParse.feedbackRole,
      assinedUserAfterParse.jobTitle
    );
    var formSchemaAfterParse = await this.formSchema.formSchemaAfterParse;
    var temp;
    var ifSave = false;
    let ft = await this.feedbackService.feedbackType
    let feedbackType = await this.feedbackService.feedbackType == 'SUBMIT_REAL_TIME_FEEDBACK' ? 'ORPHAN_FEEDBACK' : 'FEEDBACK';
    if (resOfFeedBackAfterParse.status == 'READ') {
      temp = resOfFeedBackAfterParse.submission;
      ifSave = true;
    } else {
      temp = formSchemaAfterParse.formTemplate;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '95vh';
    dialogConfig.maxWidth = '85%';
    dialogConfig.data = [
      temp,
     feedbackId,
      assinedUserAfterParse.firstName + ' ' + assinedUserAfterParse.lastName,
      ifSave,
      assinedManagerUseAfterParse.firstName +
      ' ' +
      assinedManagerUseAfterParse.lastName,
      timeFrame,
      false,
      feedbackType,
      true,
    ];
    dialogConfig.panelClass = 'feedbackform-overlay';
    const dialogRef = this.dialog
      .open(JsonFormComponent, dialogConfig)
      .afterClosed().subscribe(() => {
        
      });
  }
  async OpenProbationJSonForm(feedbackId) {
    await this.feedbackService.getProbationFeedBackByIdAsync(
      feedbackId
    );
    if(await this.feedbackService.feedbackSubmissionStatus==='SUBMITTED'){
      this.toaster.warning('Feedback Already Submitted');
      return;
    }
    var resOfFeedBackAfterParse = await this.feedbackService
      .resOfProbationFeedBackAfterParse;
    var resOfFeedBackGroupAfterParse = await this.feedbackService
      .resOfProbationFeedBackGroupAfterParse;
    var timeFrame = resOfFeedBackGroupAfterParse.year;
    await this.user.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
    var assinedUserAfterParse = await this.user.assinedUserAfterParse;
    var assinedManagerUseAfterParse = await this.user
      .assinedManagerUseAfterParse;

    await this.formSchema.getProbationFormSchemaAsync();
    var formSchemaAfterParse = await this.formSchema.formSchemaAfterParse;
    var temp;
    var ifSave = false;
    if (resOfFeedBackAfterParse.feedbackSubmissionStatus == 'READ') {
      temp = resOfFeedBackAfterParse.submission;
      ifSave = true;
    } else {
      temp = formSchemaAfterParse.formTemplate;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '95vh';
    dialogConfig.maxWidth = '85%';
    dialogConfig.data = [
      temp,
      feedbackId,
      assinedUserAfterParse.firstName + ' ' + assinedUserAfterParse.lastName,
      ifSave,
      assinedManagerUseAfterParse.firstName +
      ' ' +
      assinedManagerUseAfterParse.lastName,
      timeFrame,
      true,
      'PROBATION',
    ];
    dialogConfig.panelClass = 'feedbackform-overlay';
    const dialogRef = this.dialog
      .open(JsonFormComponent, dialogConfig)
      .afterClosed().subscribe(() => {      });
  }

  verifyAuthorities()
  {
    this.hasRoleBased = this.userDetailsService.hasRole(['ROLE_ADMIN']);
    this.hasCmRights = this.userDetailsService.hasRole(['ROLE_CAREER_MANAGER']);
  }
  // getProject() {
  //   this.talentservice
  //     .getTalentByUserIdSubscribe(this.userDetailsService.getUserDetails().id)
  //     .subscribe((res) => {
  //       for (let r of res.body) {
  //         this.projectservice.getProjectById(r.projectId).subscribe((resp) => {
  //           this.projectList.push(resp.body);
  //         });
  //       }
  //     });
  // }
}
